const starters = [
    {
      "image": "3.png",
      "name": "Chips and Salsa",
      "price": "$2.50",
      "details": "Add guacamole for an additional charge"
    },
    {
      "image": "4.png",
      "name": "Sopa de Tortilla",
      "price": "$10.95",
      "details": "Made to order tortilla soup. Diced carrots, avocado, Jack cheese and chicken in a flavorful broth"
    },
    {
      "image": "5.png",
      "name": "Grilled Corn Taco Salad",
      "price": "$10.95",
      "details": "Romaine lettuce, corn, black beans, cilantro, onions, tomatoes, cabbage and guacamole."
    },
    {
      "image": "6.png",
      "name": "8 Pieces Poppers",
      "price": "$7.95",
      "details": "8 mild jalapenos filled with cheese, breaded and flash fried served with baja sauce."
    },
    {
      "image": "7.png",
      "name": "Elote",
      "price": "$5.95",
      "details": "Corn on the cob done up the Mexican way, with grated cheese and chili powder."
    }
  ];

  const tacos = [
    {
      "image": "24.png",
      "name": "Carne Asada Taco",
      "price": "$6.75",
      "details": "Grilled steak in corn tortilla with pico de gallo and pinto beans."
    },
    {
      "image": "25.png",
      "name": "Pollo Taco",
      "price": "$6.75",
      "details": "Seasoned, simmered chicken in corn tortilla with pico de gallo and pinto beans."
    },
    {
      "image": "26.png",
      "name": "Pollo Asado Taco",
      "price": "$6.75",
      "details": "Grilled chicken in corn tortilla with pico de gallo and black beans."
    },
    {
      "image": "27.png",
      "name": "Fried Chicken Taco",
      "price": "$6.75",
      "details": "Fried chicken in a soft corn tortilla with rojo salsa, lime mayonnaise, cilantro, cabbage and onions."
    },
    {
      "image": "28.png",
      "name": "Carnitas Taco",
      "price": "$6.75",
      "details": "Braised shredded pork in corn tortilla with pico de gallo and pinto beans."
    },
    {
      "image": "29.png",
      "name": "Pescado Taco",
      "price": "$7.75",
      "details": "Baja style fried fish in a corn tortilla with salsa, lime mayonnaise, cilantro, cabbage and onions."
    },
    {
      "image": "30.png",
      "name": "Grilled Fish Taco",
      "price": "$7.75",
      "details": "Seasonal fresh fish served with tomatillo and pico de gallo salsas, cilantro, cabbage and onions in a corn tortilla."
    },
    {
      "image": "31.png",
      "name": "Camarones Taco",
      "price": "$7.75",
      "details": "Grilled tiger prawns with tomatillo and pico de gallo salsas, cilantro, cabbage and onions in a corn tortilla."
    },
    {
      "image": "32.png",
      "name": "Vegetariano Taco",
      "price": "$5.95",
      "details": "Grilled vegetables in a corn tortilla with pico de gallo and black beans."
    }
  ];

  const quesadillas = [
    {
      "image": "8.png",
      "name": "Carne Asada Quesadilla",
      "price": "$12.95",
      "details": "Grilled steak folded in a crispy flour tortilla with pico de gallo and Jack cheese."
    },
    {
      "image": "9.png",
      "name": "Pollo Quesadilla",
      "price": "$12.95",
      "details": "Seasoned chicken folded in a crispy flour tortilla with pico de gallo and Jack cheese."
    },
    {
      "image": "10.png",
      "name": "Pollo Asado Quesadilla",
      "price": "$12.95",
      "details": "Grilled chicken folded in a crispy flour tortilla with pico de gallo and Jack cheese."
    },
    {
      "image": "11.png",
      "name": "Carnitas Quesadilla",
      "price": "$12.95",
      "details": "Braised shredded pork folded in a crispy flour tortilla with pico de gallo and Jack cheese."
    },
    {
      "image": "12.png",
      "name": "Pollo Asado Quesadilla",
      "price": "$12.95",
      "details": "Grilled chicken folded in a crispy flour tortilla with pico de gallo and Jack cheese."
    },
    {
      "image": "13.png",
      "name": "Poco Queso Quesadilla",
      "price": "$5.95",
      "details": "Melted Jack cheese folded in a crispy flour tortilla."
    },
    {
      "image": "14.png",
      "name": "Vegetarian Quesadilla",
      "price": "$11.95",
      "details": "Vegetarian"
    },
    {
      "image": "16.jpg",
      "name": "Vegan Quesadilla",
      "price": "$11.95",
      "details": "Vegan"
    }
  ];

  const burritos = [
    {
      "name": "Pollo Burrito",
      "image": "17.png",
      "price": "$12.95",
      "details": "Seasoned, simmered chicken rolled in a flour tortilla with pico de gallo and pinto beans."
    },
    {
      "name": "Pollo Asado Burrito",
      "image": "18.png",
      "price": "$12.95",
      "details": "Grilled chicken rolled in a flour tortilla with pico de gallo and black beans."
    },
    {
      "name": "Carnitas Burrito",
      "image": "19.png",
      "price": "$12.95",
      "details": "Braised shredded pork rolled in a flour tortilla with pico de gallo and pinto beans."
    },
    {
      "name": "Pescado Burrito",
      "image": "20.png",
      "price": "$13.95",
      "details": "Baja style fried fish rolled in a flour tortilla with baja salsas, cilantro, cabbage and onions."
    },
    {
      "name": "Grilled Fish Burritos",
      "image": "21.png",
      "price": "$15.95",
      "details": "Seasonal fresh fish rolled in a flour tortilla with tomatillo and pico de gallo salsas, cilantro, cabbage and onions."
    },
    {
      "name": "Camarones Burrito",
      "image": "22.png",
      "price": "$15.95",
      "details": "Grilled tiger prawns rolled in a flour tortilla with tomatillo and pico de gallo salsas, cilantro, cabbage and onions"
    },
    {
      "name": "Vegetariano Burrito",
      "image": "23.png",
      "price": "$11.95",
      "details": "Jack cheese, pinto beans, pico de gallo, guacamole, cabbage and onions rolled in a flour tortilla. Vegetarian."
    }
  ];
  
  
  

  export {starters, tacos, quesadillas, burritos }
  