import React, { useContext } from 'react'
import { CompanyContext } from '../../contexts/Company';

function Logo({ isDark, ...parms }) {

  const company = useContext(CompanyContext);

  return (
    <img
      {...parms}
      src={
        isDark ? company.logoDark
          : company.logoLight
      }
      alt='logo'
    />
  )
}

export default Logo