import React from 'react'
import { Link } from 'react-router-dom';
import { GiChiliPepper } from 'react-icons/gi';


function MainMenu({ title, subtitle, sideImage, isImgRight, foodItems }) {


  function splitArray(arr) {
    const mid = Math.ceil(arr.length / 2); // Find the middle index
    const firstHalf = arr.slice(0, mid); // Split the array into two parts
    const secondHalf = arr.slice(mid);

    return [firstHalf, secondHalf]; // Return the two arrays
  }

  const [firstHalf, secondHalf] = foodItems && Array.isArray(foodItems) ? splitArray(foodItems) : null;
  const imageRoute = 'https://nickscristytacos.s3.us-west-1.amazonaws.com/menu_images/';


  return (
    <>
      <section className="menu-two">

        <div className={isImgRight ? 'right-bg' : 'left-bg'}><img style={{ height: 700 }} src={sideImage} alt="" title="" /></div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle"><span>{subtitle}</span></div>

            <div className="pattern-image">
              <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            </div>

            <h2>{title}</h2>
          </div>
          <div className="row clearfix">
            <div className="menu-col col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                {firstHalf.map((item, key) => (
                  <div key={key} className="dish-block">
                    <div className="inner-box">
                      <div className="dish-image"><Link href="#"><img src={imageRoute + item.image} alt="" /></Link></div>
                      <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">{item.name}</Link></h5></div><div className="price"><span><GiChiliPepper size={45} /></span></div></div>
                      {/* <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">{item.name}</Link></h5></div><div className="price"><span>{item.price}</span></div></div> */}
                      <div className="text desc"><Link href="#">{item.details}</Link></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="menu-col col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                {secondHalf.map((item, key) => (
                  <div key={key} className="dish-block">
                    <div className="inner-box">
                      <div className="dish-image"><Link href="#"><img src={imageRoute + item.image} alt="" /></Link></div>
                      <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">{item.name}</Link></h5></div><div className="price"><span><GiChiliPepper size={45} /></span></div></div>
                      {/* <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">{item.name}</Link></h5></div><div className="price"><span>{item.price}</span></div></div> */}
                      <div className="text desc"><Link href="#">{item.details}</Link></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MainMenu