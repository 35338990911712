const menuRoutes = [
  {
    name : 'Home',
    route : '/'
  },
  {
    name : 'Menu',
    route : '/menu',
  },
  {
    name: 'Reservation',
    route: 'https://www.yelp.com/reservations/nicks-crispy-tacos-san-francisco-2?from_reserve_now=1',
    isOutbound : 'true'
  },
  {
    name: 'Catering',
    route: 'https://www.ezcater.com/catering/pvt/nicks-crispy-tacos-3?fcv=1',
    isOutbound : 'true'
  },
  {
    name: 'Rockwell',
    route: 'https://rockwellrock.com/',
    isOutbound : 'true'
  },
  {
    name: 'Our Facebook',
    route: 'https://www.facebook.com/NicksCrispyTacosSF/',
    isOutbound : 'true'
  },
  {
    name : 'Contact',
    route : '/contact'
  }
];

export default menuRoutes;