import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import { CompanyContext } from "./contexts/Company";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/responsive.css'
import './assets/css/main.css';
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from "react";
import getCompany from './api/company';
import { ToastContainer } from "react-toastify";

// import './assets/css/owl.css'

export default function App() {
  const [company, setCompany] = useState({});

  useEffect(() => {
    async function getData() {
      let myCompany = await getCompany();
      setCompany(myCompany)
    }
    getData()
  }, [])

  return (
    <CompanyContext.Provider value={company}>
      <Router>
        <Routing />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Router>
    </CompanyContext.Provider>
  );
}
