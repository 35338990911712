import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { CompanyContext } from '../../contexts/Company'
import menuRoutes from '../../routes/menuRoutes';
import formatPhoneNumber from '../../helpers/formatPhoneNumber';
import Logo from '../main/Logo';
import { FaFacebook } from 'react-icons/fa';


function Main() {
  const company = useContext(CompanyContext);
  const [active, setActive] = useState();
  const [lastscrool, setlastscrool] = useState(0);

  const [scrolled, setScrolled] = useState(false);
  const [Nav, setNav] = useState(true);

  const handleScroll = () => {

    const offset = window.scrollY;
    setlastscrool(offset);

    if (offset > 1000 && offset < lastscrool) {
      setNav(true);
      setScrolled(true);
    }

    else if (offset > 1000) {
      setNav(false)
    }

    else if (offset > 200) {
      setNav(true);
      setScrolled(true);
    }

    else {
      setScrolled(false);
      setNav(true);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })


  return (

    <>
      {active && <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }} onClick={() => setActive(false)}></div>}

      <section className={`hidden-bar ${active && "visible-sidebar"}`} onClick={() => setActive(false)} >
        <div className="inner-box">
          <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close"> </span></div>
          <div className="text-center" style={{marginBottom: 40}}><Link to="/" title=""><Logo width="120" className="img-fluid" />
          </Link></div>

          <div className="side-menu">
            <ul className="navigation clearfix">
              {menuRoutes.map((route, key) => (
                <li key={key}>
                  {route?.isOutbound ? <a href={route.route} target='_BLANK' rel="noreferrer">{route.name}</a> : <Link to={route.route}>{route.name}</Link>}
                </li>
              ))}
            </ul>
          </div>

          <h2>Visit Us</h2>
          <ul className="info">
            <li>{company?.address}, {company?.city}, {company?.state} {company?.zip}</li>
            <li>Monday: CLOSED</li>
            <li>Tuesday: 11:00 am - 10:00pm</li>
            <li>Wed - Fri: 11:00 am - 9:00pm</li>
            <li>Sat - Sun: 10:00 am - 9:00pm</li>
            <li><Link to={`mailto:${company?.email}`}>{company?.email}</Link></li>
          </ul>
          <div className="separator"><span></span></div>
          <div className="booking-info">
            <div className="bk-title">Have Questions?</div>
            <div className="bk-no"><a href={`tel:${company?.phone}`}>{formatPhoneNumber(company?.phone)}</a></div>
          </div>

        </div>
      </section>
      {Nav &&
        <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
          <div className="header-top">
            <div className="auto-container">
              <div className="inner clearfix">
                <div className="top-left clearfix">
                  <ul className="top-info clearfix">
                    {/* <li><i className="icon far fa-map-marker-alt"></i> {company?.address}, {company?.city}, {company?.state} {company?.zip}</li> */}
                    {/* <li><i className="icon far fa-clock"></i> Daily : 11:00 am to 10.00 pm</li> */}
                  </ul>
                </div>
                <div className="top-right clearfix">
                  <ul className="top-info clearfix">
                    {/* <li><a href={`tel:${company?.phone}`}><i className="icon far fa-phone"></i> {formatPhoneNumber(company?.phone)} </a> </li> */}
                    <li><a href={`mailto:${company?.email}`}><i className="icon far fa-envelope"></i>{company?.email}</a></li>
                    <li><a href={`https://www.facebook.com/NicksCrispyTacosSF/`} target='_BLANK' rel="noreferrer"><FaFacebook className='far' /> NicksCrispyTacosSF</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper header-fixed" >
            <div className="auto-container">
              <div className="main-box d-flex justify-content-between align-items-center flex-wrap">

                <div className="logo-box">
                  <div className="logo"><Link to="/"><Logo className='img-fluid' style={{ height: 100 }} /></Link></div>
                </div>

              </div>
            </div>
          </div>
        </header>}

    </>

  )
}

export default Main