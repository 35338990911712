import React from 'react'


function Main({ subtitle, img, title }) {
  return (

    <>

      <section className="inner-banner">
        <div className="image-layer" style={{ backgroundImage: `url(${img || 'https://nickscristytacos.s3.us-west-1.amazonaws.com/banners/2.png'})`, filter: 'brightness(90%)' }}></div>
        <div className="auto-container">
          <div className="inner">
            <div className="subtitle"><span> {title} </span></div>

            <div className="pattern-image">

              <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

            </div>

            <h1><span>{subtitle}</span></h1>
          </div>
        </div>
      </section>

    </>

  )
}

export default Main