export default function formatPhoneNumber(phoneNumber) {
  if (phoneNumber) {
    // Remove any non-numeric characters from the phone number
    phoneNumber = phoneNumber.replace(/\D/g, '');

    // Check if the phone number is less than 10 digits or more than 11 digits
    if (phoneNumber.length < 10 || phoneNumber.length > 11) {
      return phoneNumber;
    }

    // Check if the phone number is 11 digits and starts with a 1
    if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
      // Remove the first digit
      phoneNumber = phoneNumber.slice(1);
    }

    // Format the phone number as (xxx) xxx-xxxx
    return '(' + phoneNumber.slice(0, 3) + ') ' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6);
  } else {
    return '(777)777-7777'
  }
}