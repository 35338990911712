import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import Contact from '../pages/Contact';
import Landing from '../pages/Landing';
import Menu from '../pages/Menu';

function Index() {
  const location = useLocation();
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
  }, [path]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {/* <Footer /> */}
    </>
  )
}

export default Index