import {Get} from './'

async function getCompany () {
    let address = window.location.hostname;
    
    return await Get('/companies/getByAddress/' + address).then(res => {
        console.log(res)
        return res || {}
    });
}

export default getCompany;