import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);


function Banner() {

  const MySlide = ({ image, title, subtitle, detail, buttonName, buttonRoute, brightness }) => {
    return (
      <div className="swiper-slide slide-item">

        <div className="image-layer"
          style={{
            backgroundImage: `url('${image}')`,
            // https://nickscristytacos.s3.us-west-1.amazonaws.com/banners/1.png
            filter: `brightness(${brightness || '40'}%)`
          }}
        ></div>

        <div className="auto-container">
          <div className="content-box">
            <div className="content">
              <div className="clearfix">
                <div className="inner">
                  {/* <div className="subtitle"><span>{subtitle}</span></div>
                  <div className="pattern-image">
                    <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

                  </div> */}
                  <h1><span style={{ whiteSpace: 'pre-line' }}>{title}</span></h1>
                  <div className="text">{detail}</div>
                  <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    {buttonRoute ? (
                      <div className="link">
                        <Link to={`${buttonRoute}`} className="theme-btn btn-style-two clearfix">
                          <span className="btn-wrap">
                            <span className="text-one">{buttonName}</span>
                            <span className="text-two">{buttonName}</span>
                          </span>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }




  return (
    <>

      <Swiper className="banner-section"
        loop={true}
        allowSlideNext={true}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          nextEl: '.swiper-button-prev',
          prevEl: '.swiper-button-next',
        }}>

        <div className="banner-container">
          <div className="banner-slider" >
            <div className="swiper-wrapper">
              {/* <SwiperSlide>
                <MySlide
                  image="https://nickscristytacos.s3.us-west-1.amazonaws.com/images/bar/7.jpg"
                  title={`Welcome to\nNick's Crispy Tacos`}
                  subtitle="Coming Soon”"
                  detail="Our new location is now opened"
                  brightness={'80'}
                />
              </SwiperSlide> */}

              <SwiperSlide>
                <MySlide
                  image="https://nickscristytacos.s3.us-west-1.amazonaws.com/banners/1.png"
                  title={`Important News`}
                  subtitle=""
                  detail= "We will be closed for a Winter / Spring Break. We will keep you updated on our reopening"
                  // buttonName={'view our menu'}
                  // buttonRoute="/menu"
                />
              </SwiperSlide>


              {/* <SwiperSlide>
                <div className="swiper-slide slide-item">
                  <div className="image-layer"
                    style={{
                      backgroundImage: `url('https://nickscristytacos.s3.us-west-1.amazonaws.com/banners/1.png')`,
                      filter: 'brightness(40%)'
                    }}
                  ></div>

                  <div className="auto-container">
                    <div className="content-box">
                      <div className="content">
                        <div className="clearfix">
                          <div className="inner">
                            <div className="subtitle"><span>delightful experience</span></div>

                            <div className="pattern-image">

                              <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

                            </div>


                            <h1><span>Flavors Inspired <br />by Mexico</span></h1>
                            <div className="text">Come with family & feel the joy of mouthwatering food</div>
                            <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                              <div className="link">
                                <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                  <span className="btn-wrap">
                                    <span className="text-one">view our menu</span>
                                    <span className="text-two">view our menu</span>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
            </div>

            {/* <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
                        <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div> */}


          </div>
        </div>

        {/* <div className="book-btn">
                    <Link to="#" className="theme-btn">
                        <span className="icon">
                            <img src={'https://nickscristytacos.s3.us-west-1.amazonaws.com/banners/1.png'} alt="" title="" />
                        </span>
                        <span className="txt">book a table</span>
                    </Link>
                </div> */}

      </Swiper>

    </>
  )
}

export default Banner