import React from 'react'
import Bredcrumb from '../components/Bredcrumb/Main';
import MainMenu from '../components/main/MainMenu';
import bg19 from '../assets/images/background/2.png'
import bg20 from '../assets/images/background/1.png'
import bg1 from '../assets/images/background/tacoBg.png'
import { burritos, quesadillas, starters, tacos } from '../data/menu';

function Menu() {
  return (
    <>
      <Bredcrumb
        subtitle="Our Menu"
        title="DELICIOUS & AMAZING"
        img={'https://nickscristytacos.s3.us-west-1.amazonaws.com/banners/14.png'}
      />
      <MainMenu title={'Appetizers'} subtitle={'Starters'} sideImage={bg20} foodItems={starters} />
      <MainMenu title={'Taco\'s'} subtitle={'From Mexico'} sideImage={bg19} foodItems={tacos} isImgRight />
      {/* <MenuSm title={'Favorite\'s'} subtitle={'Family Friendly'} foodItems={quesadillas} sideImage={offerimg2} /> */}
      <MainMenu title={'Quesadilla\'s'} subtitle={'Cheese me please'} foodItems={quesadillas} sideImage={bg1} />
      <MainMenu title={'burrito\'s'} subtitle={'Cheese me please'} foodItems={burritos} sideImage={bg1} isImgRight />
    </>
  )
}

export default Menu