import { MapContainer, TileLayer, Marker, Popup, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Create a custom icon for the marker
const customIcon = L.icon({
  iconUrl: 'https://nickscristytacos.s3.us-west-1.amazonaws.com/logos/logoLight.png',
  iconSize: [100, 50],
  iconAnchor: [10, 65],
});

export default function ContactMap({ zoom }) {
    return (
        <div className=''>
            <MapContainer
                style={{ width: '100%', height: 500 }}
                id='mapid'
                center={[37.798952, -122.435878]}
                zoom={zoom || 14}
                zoomControl={true}
                scrollWheelZoom={false}
            >
                <TileLayer
                    attribution='&copy; <a href="https://Liveonbeat.com">Live On Beat</a>'
                    url="https://api.mapbox.com/styles/v1/djstewie/clh5ndv45009c01rhff2w1ask/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGpzdGV3aWUiLCJhIjoiY2trcGQ2MW84MDZhNjJ5bzVrbm8xemhycSJ9.y592_-MfDaduUdSMbT66iQ"
                />
                <LayerGroup>
                    <Marker position={[37.798952, -122.435878]} icon={customIcon}>
                        {/* <Popup>
                            A popup for your marker!
                        </Popup> */}
                    </Marker>
                </LayerGroup>
            </MapContainer>
        </div>
    )
}
