import React from 'react';
import Banner from '../components/Menhome/Banner';
import Offer from '../components/Menhome/Offer';
import PromoAd from '../components/main/PromoAd';

export default function Landing() {
  return (
    <>
      <Banner />
      {/* <Offer />
      <PromoAd
        title={'Taco Tuesday\'s'}
        subtitle={'Specials Start at 5:00pm'}
        details={`Looking for a great deal on delicious tacos? Look no further than Taco Tuesday's at Nick's Crispy Tacos!`}
        date={'EVERY TUESDAY'}
        buttonName={''}
        buttonRoute={''}
        image1={'https://nickscristytacos.s3.us-west-1.amazonaws.com/menu_images/28.png'}
        image2={'https://nickscristytacos.s3.us-west-1.amazonaws.com/images/bar/6.jpg'}
        isImagesRight
      /> */}
    </>
  )
}
