import React, { useContext } from 'react'
import Bredcrumb from '../components/Bredcrumb/Main';
import Iframe from 'react-iframe';
import bg1 from '../assets/images/background/tacoBg.png'
import { Link } from 'react-router-dom';
import { CompanyContext } from '../contexts/Company';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import ContactMap from '../components/main/ContactMap';
import { toast } from 'react-toastify';
import { Post } from '../api';
import validateEmail from '../helpers/validateEmail';

function Contact() {
  const company = useContext(CompanyContext);
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [subject, setSubject] = React.useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !phone || !message) {
      toast.error('Please fill out all fields');
      return;
    }

    if (!validateEmail(email)) {
      toast.error('Please enter an email address');
      return;
    }

    Post('/contacts', {
      first_name: name,
      phone: phone,
      email: email,
      subject: subject,
      message: message,
      company: company._id
    })
      .then(res => {
        toast.success('Thank you for contacting us!');
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setSubject('');
      }).fail(err => {
        toast.error('Something went wrong. Please try again later.');
      });
  }

  return (
    <>
      <Bredcrumb
        subtitle="Questions?"
        title="Contact Us"
        img="https://nickscristytacos.s3.us-west-1.amazonaws.com/images/bar/8.jpg"
      />
      <div className="contact-map">
        <ContactMap />
      </div>

      <section className="contact-page">
        <div className="left-bg"><img src={bg1} alt="" title="" /></div>
        <div className="right-bg"><img src={bg1} alt="" title="" /></div>

        <div className="location-center">
          <div className="auto-container">
            <div className="cinfo-box">
              <div className="row clearfix">

                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                    <h4>Taco Tuesday's</h4>
                    <div className="text"> Every Tuesday <br /> 5:00pm - 10:00pm</div>
                  </div>
                </div>


                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                    <h4>Contact Info</h4>
                    <div className="text">{company?.address}, {company?.city}, {company?.state} {company?.zip}<br />Email : {company?.email}</div>
                    <div className="more-link"><a href={`tel:${company?.phone}`}>Catering? : {formatPhoneNumber(company?.phone)}</a></div>
                  </div>
                </div>


                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <h4>Our Hours</h4>
                    <div className="text"> Tuesday: 11:00am - 10:00pm</div>
                    <div className="text"> Wed - Fri: 11:00am - 9:00pm</div>
                    <div className="text"> Sat - Sun: 10:00am - 9:00pm</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="auto-container">
          <div className="c-page-form-box">
            <div className="row clearfix">

              <div className="loc-block col-lg-12 col-md-12 col-sm-12">
                <div className="title-box centered">
                  <h2>Message us</h2>
                  <div className="text desc">Have a question about the our service? We're here to help, contact us today</div>
                </div>
                <div className="default-form reservation-form">
                  <form>
                    <div className="clearfix">
                      <div className="form-group">
                        <div className="field-inner">
                          <input value={name} onChange={(e) => setName(e.target.value)} type="text" name="fieldname" placeholder="Your Name" required="" />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" name="fieldname" placeholder="Your Email" required="" />
                        </div>
                      </div>
                      <div className="form-group ">
                        <div className="field-inner">
                          <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" name="fieldname" placeholder="Phone Number" required="" />
                        </div>
                      </div>

                      <div className="form-group ">
                        <div className="field-inner">
                          <input value={subject} onChange={(e) => setSubject(e.target.value)} type="text" name="fieldname" placeholder="Subject" required="" />
                        </div>
                      </div>

                      <div className="form-group ">
                        <div className="field-inner">
                          <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="fieldname" placeholder="Special Request" required=""></textarea>
                        </div>
                      </div>

                      <div className="form-group">
                        <button onClick={handleSubmit} className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                            <span className="text-one">send your message</span>
                            <span className="text-two">send your message</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact